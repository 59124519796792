import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Heading2 } from '../../atoms/Headings/Headings';
import {
  breakpoints,
  colors,
  durations,
  fontFamilies,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import ClientCarouselCard from '../../molecules/ClientCarouselCard/ClientCarouselCard';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';
import quoteMarksPng from './assets/quote-marks.png';
import Section from '../../molecules/Section/Section';

const ClientCarouselSection = ({
  carouselClients,
  sectionHeading,
  sectionStyles,
}) => {
  const slideWidth = 1430;

  const slideWidthInRems = uc(`${slideWidth}px`);
  const sectionCss = css`
    position: relative;
    left: calc(50% - (${slideWidthInRems} / 2));
    width: ${slideWidthInRems};

    @media (${breakpoints.largeTablet}) {
      left: 0;
      width: 100%;
    }

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      display: flex;
      padding: ${uc('50px 0')};

      .carousel-card {
        &::after {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: ${colors.lightGray.one};
          transition: ${durations.medium};
          pointer-events: none;
          content: '';
        }
      }

      &.slick-current {
        .carousel-card {
          &::after {
            opacity: 0;
          }
        }
      }

      > div {
        display: inline-block;
        margin: auto;
      }
    }

    .slick-dots {
      top: ${uc('-64px')};
      bottom: auto;

      button {
        width: auto;
        height: auto;

        &::before {
          display: none;
        }
      }

      li {
        width: auto;
        height: auto;
        margin: ${uc('0 50px')};
        vertical-align: middle;

        &::after {
          position: absolute;
          right: ${uc('-35px')};
          bottom: ${uc('-9px')};
          display: flex;
          justify-content: center;
          align-items: center;
          width: ${uc('47px')};
          height: ${uc('47px')};
          color: ${colors.white};
          font-size: ${uc('30px')};
          font-family: ${fontFamilies.proximaSoft};
          background: ${colors.accent};
          border-radius: 50%;
          transform: scale(0.9);
          opacity: 0;
          transition: ${durations.medium};
          content: url(${quoteMarksPng});
        }

        img {
          max-width: 150px;
          height: auto;
          max-height: 60px;
          filter: contrast(50%) sepia(1) hue-rotate(-200deg) saturate(70%)
            grayscale(0.2);
        }

        &.slick-active {
          &::after {
            transform: scale(1);
            opacity: 1;
          }

          img {
            filter: none;
          }
        }
      }

      @media (${breakpoints.largeTablet}) {
        ul {
          display: flex;
          width: 100%;
          overflow-x: scroll;
          overflow-y: visible;
        }

        li {
          height: ${uc('80px')};

          &::after {
            bottom: ${uc('8px')};
          }
        }

        img {
          height: 50px;
        }
      }
    }
  `;

  const heading2Css = css`
    width: 55%;
    margin: ${uc('0 auto 117px')};
    color: ${colors.darkGray.two};
    line-height: ${lineHeights.copy};
    text-align: center;

    @media (${breakpoints.largeTablet}) {
      width: 60%;
    }

    @media (${breakpoints.tablet}) {
      width: 70%;
    }

    @media (${breakpoints.mobile}) {
      width: 100%;
    }
  `;

  const customDots = carouselClients.map(client => client.clientLogoNav);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToScroll: 1,
    initialSlide: 0,
    slidesToShow: 1,
    arrows: false,
    className: customDots.length === 1 ? 'slick-active' : '',
    autoplay: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    appendDots: dots => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <button type="button">
        <Image
          src={sanityImage(customDots[i].asset)
            .auto('format')
            .width('150')
            .url()}
          alt={customDots[i].alt}
        />
      </button>
    ),
  };

  const renderedCards = carouselClients.map(client => (
    <ClientCarouselCard key={client._key} {...client} {...sectionStyles} />
  ));

  return (
    <Section sectionStyles={sectionStyles}>
      <div css={sectionCss}>
        <Heading2 css={heading2Css}>{sectionHeading}</Heading2>
        <Slider {...sliderSettings}>{renderedCards}</Slider>
      </div>
    </Section>
  );
};

ClientCarouselSection.propTypes = {
  carouselClients: PropTypes.arrayOf(PropTypes.shape({})),
  sectionHeading: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

ClientCarouselSection.defaultProps = {
  carouselClients: [],
  sectionHeading: '',
  sectionStyles: {},
};

export default ClientCarouselSection;
